
import { defineComponent, ref } from 'vue'
import { getUserInfo,getUserCertificationInfo,getUserGoodsInfo } from '@/api/index'
import { API_BASE_URL } from '@/service/request/config'
import localCache from '@/global/cache'
import { Picture as IconPicture } from '@element-plus/icons-vue'
export default defineComponent({
name: 'articleContent',
setup() {
    const userInfoMsg:any = ref({
        username:null,
        name:null,
        pic:null,
        sex:null,
        age:null,
        student_num:0,
        last_login_ip:null,
        last_login_time:null,
        created_at:null
    })

    const certificateList:any = ref([])
    const goodsInfo:any = ref({})
    const getDataInfo = async () =>{
        let userId = null;
        if(localCache.getCache('userInfoMsg')){
            userId = localCache.getCache('userInfoMsg').users_id

        }
       let dataMsg = await getUserInfo({users_id:userId})
       if(dataMsg.errno == 0){
        
        userInfoMsg.value.username = dataMsg.data.username
        userInfoMsg.value.name = dataMsg.data.name
        userInfoMsg.value.pic = dataMsg.data.pic
        userInfoMsg.value.sex = dataMsg.data.sex
        userInfoMsg.value.age = dataMsg.data.age
        userInfoMsg.value.student_num = dataMsg.data.student_num
        userInfoMsg.value.last_login_ip = dataMsg.data.last_login_ip
        userInfoMsg.value.last_login_time = dataMsg.data.last_login_time
        userInfoMsg.value.created_at = dataMsg.data.created_at
       }
    }
    const getCertificateInfo = async () => {
       let dataMsg = await getUserCertificationInfo({})
       if(dataMsg.errno == 0){

        certificateList.value = dataMsg.data;
       }
    }
    getDataInfo()
    getCertificateInfo()

    const getGoodsInfo = async () => {
        let dataMsg = await getUserGoodsInfo({})
        if(dataMsg.errno == 0){
            goodsInfo.value = dataMsg.data    
        }
    }
    getGoodsInfo()

    const currentTime = (timestamp:any) => {
        let date = new Date(timestamp);
        let Year = date.getFullYear();
        let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        let  GMT =  Year + '-' + Moth + '-' + Day + '   '+ Hour +':'+ Minute  + ':' + Sechond;
        return GMT;
    }
    return {
        userInfoMsg,
        API_BASE_URL,
        certificateList,
        currentTime,
        goodsInfo
    }
}
})
